<template>
  <c-box
    max-width="1270px"
    width="100%"
    :background-color="['#F2F2F2', '#FFF']"
    :padding-bottom="['125px', '0px']"
    margin-inline="auto"
    :min-height="['calc(100vh - 62px)', 'unset']"
  >
    <c-flex
      :display="['none', 'flex']"
      width="100%"
      align-items="center"
      gap="80px"
      py="20px"
    >
      <c-box
        display="flex"
        align-items="center"
        font-size="16px"
        font-weight="400"
        color="primary.400"
        gap="20px"
        cursor="pointer"
        @click="$router.back()"
      >
        <inline-svg
          :src="require('@/assets/icon-chevron-left.svg')"
          height="20"
          width="20"
        />
        Kembali
      </c-box>
      <BreadcrumbPath
        margin-top="0px"
        :paths="[
          { label: 'Dashboard', href: '/' },
          { label: 'Profil', href: '/profile' },
          { label: 'Kode AjakSehat', href: '/profile/referral' },
          { label: 'Hadiah', href: '/profile/referral/redeem' },
          { label: isConfirm ? 'Konfirmasi Penukaran' : 'Detail Hadiah', isCurrent: true },
        ]"
      />
    </c-flex>
    <c-text
      :display="['none', 'block']"
      text-align="center"
      font-family="Roboto"
      font-size="20px"
      font-weight="700"
      mb="20px"
      color="#333"
    >
      {{ isConfirm ? 'Konfirmasi Penukaran' : 'Detail Hadiah' }}
    </c-text>
    <c-grid
      w="100%"
      :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
      gap="16px"
    >
      <c-box
        width="100%"
        :p="['0px', '30px']"
        :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
        :border-radius="['none', '12px']"
        background-color="#FFF"
      >
        <c-flex
          :display="['none', 'flex']"
          justify-content="space-between"
          align-items="center"
        >
          <c-text
            :font-size="['18px', '20px']"
            :font-weight="['500', 'Bold']"
          >
            Detail Hadiah
          </c-text>
        </c-flex> 
        <c-divider
          :display="['none', 'block']"
        />
        <c-flex
          :flex-direction="['column', 'row']"
          :gap="['16px']"
          :margin-bottom="['16px', '16px']"
        >
          <c-image 
            v-if="gift?.image"
            :src="gift?.image"
            flex-shrink="0"
            :width="['100%', '225px']"
            :height="['100%']"
            :max-height="['auto', '125px']"
            object-fit="cover"
          />
          <c-box
            v-else
            flex-shrink="0"
            :width="['100%', '225px']"
            :height="['220px', '125px']"
          >
            <vue-skeleton-loader
              type="rect"
              width="100%"
              height="100%"
              animation="fade"
            />
          </c-box>
          <c-flex
            flex-grow="1"
            flex-direction="column"
          >
            <c-text
              :font-size="['16px', '18px']"
              :font-weight="['500', '500']"
              :text-align="['center', 'left']"
            >
              {{ gift?.name }}
            </c-text>
            <c-box
              margin-top="8px"
              :display="['none', 'block']"
            >
              <c-text
                :font-size="['18px']"
                :font-weight="['500']"
              >
                Deskripsi Hadiah
              </c-text>
              <c-text
                :display="['none', 'block']"
                :font-size="['16px']"
                :font-weight="['400']"
              >
                {{ gift?.description }}
              </c-text>
            </c-box>
          </c-flex>
        </c-flex>
        <c-box
          v-if="gift?.type == 'saldo' || gift?.type == 'programs'"
          margin-bottom="16px"
          :padding-inline="['16px', '0px']"
        >
          <c-box
            v-if="gift?.type == 'saldo'"
          >
            <c-text
              :font-size="['14px', '18px']"
              :font-weight="['500', '500']"
              :margin-bottom="['8px']"
            >
              Data Penerima
            </c-text>
            <c-form-control
              is-required
              :mb="['12px', '16px']"
              :is-invalid="$v.form.merchantName?.$invalid"
            >
              <c-form-label
                :font-size="['14px', '16px']"
                :color="isConfirm ? 'primary.400' : '#555555'"
                font-weight="400"
                font-family="Roboto"
                padding-left="8px"
              >
                Nama Merchant/Bank
              </c-form-label>
              <c-input-group
                v-if="!isConfirm"
                size="md"
              >
                <c-select
                  v-model="form.merchantName"
                  placeholder="Pilih merchant/bank"
                  :height="['48px', '62px']"
                  :font-size="['14px', '18px']"
                  :border-radius="['8px']"
                  :is-invalid="false"
                >
                  <option
                    v-for="item in addOns"
                    :key="item?.id"
                    :value="item?.bankName"
                  >
                    {{ item?.bankName || '-' }}
                  </option>
                </c-select>
              </c-input-group>
              <c-text
                v-if="isConfirm"
                :font-size="['14px', '16px']"
                color="#111"
                font-weight="400"
                padding-left="8px"
              >
                {{ form.merchantName === 'Lainnya (tambahkan)' ? form.otherMerchant : form.merchantName }}
              </c-text>
            </c-form-control>
            <c-form-control
              v-if="!isConfirm && form.merchantName === 'Lainnya (tambahkan)'"
              is-required
              :mb="['12px', '16px']"
              :is-invalid="$v.form.otherMerchant?.$invalid"
            >
              <c-input-group
                size="md"
                :mt="['8px', '12px']"
              >
                <c-input
                  v-model="form.otherMerchant"
                  type="text"
                  error-border-color="red.300"
                  placeholder="Masukkan merchant/bank lainnya"
                  :height="['48px', '62px']"
                  :font-size="['14px', '18px']"
                  :border-radius="['8px']"
                />
              </c-input-group>
            </c-form-control>

            <c-form-control
              is-required
              :mb="['12px', '16px']"
              :is-invalid="$v.form.accountNumber?.$invalid"
            >
              <c-form-label
                :font-size="['14px', '16px']"
                :color="isConfirm ? 'primary.400' : '#555555'"
                font-weight="400"
                font-family="Roboto"
                padding-left="8px"
              > 
                Nomor E-Wallet/Rekening
              </c-form-label>
              <c-input-group
                v-if="!isConfirm"
                size="md"
              >
                <c-input
                  v-model="form.accountNumber"
                  type="text"
                  placeholder="Masukkan nomor e-wallet/rekening"
                  :height="['48px', '62px']"
                  :font-size="['14px', '18px']"
                  :border-radius="['8px']"
                />
              </c-input-group>
              <c-text
                v-if="isConfirm"
                :font-size="['14px', '16px']"
                color="#111"
                font-weight="400"
                padding-left="8px"
              >
                {{ form.accountNumber }}
              </c-text>
            </c-form-control>

            <c-form-control
              is-required
              :mb="['12px', '16px']"
              :is-invalid="$v.form.accountName?.$invalid"
            >
              <c-form-label
                :font-size="['14px', '16px']"
                :color="isConfirm ? 'primary.400' : '#555555'"
                font-weight="400"
                font-family="Roboto"
                padding-left="8px"
              > 
                Nama Penerima E-Wallet/Rekening
              </c-form-label>
              <c-input-group
                v-if="!isConfirm"
                size="md"
              >
                <c-input
                  id="account-name"
                  v-model="form.accountName"
                  type="text"
                  placeholder="Masukkan nama penerima"
                  :height="['48px', '62px']"
                  :font-size="['14px', '18px']"
                  :border-radius="['8px']"
                />
              </c-input-group>
              <c-form-helper-text 
                v-if="!isConfirm" 
                id="account-name" 
                padding-left="8px"
                :font-size="['12px', '14px']"
              >
                Nama yang diisi sesuai dengan nama pada buku rekening atau e-wallet
              </c-form-helper-text>
              <c-text
                v-if="isConfirm"
                :font-size="['14px', '16px']"
                color="#111"
                font-weight="400"
                padding-left="8px"
              >
                {{ form.accountName }}
              </c-text>
            </c-form-control>
          </c-box>

          <c-box
            v-if="gift?.type == 'programs'"
          >
            <c-text
              :font-size="['14px', '18px']"
              :font-weight="['500', '500']"
              :margin-bottom="['8px']"
            >
              Jenis Program Pilihan
            </c-text>
            <c-form-control
              is-required
              :mb="['12px', '16px']"
              :is-invalid="$v.form.productServiceId?.$invalid"
            >
              <c-form-label
                :font-size="['14px', '16px']"
                :color="isConfirm ? 'primary.400' : '#555555'"
                font-weight="400"
                font-family="Roboto"
                padding-left="8px"
              >
                Pilih program
              </c-form-label>
              <c-input-group
                v-if="!isConfirm"
                size="md"
              >
                <c-select
                  v-model="form.productServiceId"
                  placeholder="Jenis Program"
                  :height="['48px', '62px']"
                  :font-size="['14px', '18px']"
                  :border-radius="['8px']"
                  :is-invalid="false"
                >
                  <option
                    v-for="item in addOns"
                    :key="item?.id"
                    :value="item?.id"
                  >
                    {{ getProgramName(item?.id) }}
                  </option>
                </c-select>
              </c-input-group>
              <c-text
                v-if="isConfirm"
                :font-size="['14px', '16px']"
                color="#111"
                font-weight="400"
                padding-left="8px"
              >
                {{ getProgramName(form.productServiceId) }}
              </c-text>
            </c-form-control>
          </c-box>
        </c-box>
        <c-box
          :display="['block', 'none']"
          margin-bottom="16px"
          :padding-inline="['16px', '0px']"
        >
          <c-text
            :font-size="['14px', '18px']"
            :font-weight="['500', '500']"
            :margin-bottom="['8px']"
          >
            Deskripsi Hadiah
          </c-text>
          <c-text
            :font-size="['14px', '16px']"
            :font-weight="['400', '400']"
          >
            {{ gift?.description }}
          </c-text>
        </c-box>
        <c-box
          :margin-bottom="['16px', '0px']"
          :padding-inline="['16px', '0px']"
        >
          <c-text
            :font-size="['14px', '18px']"
            :font-weight="['500', '500']"
            :margin-bottom="['8px']"
          >
            Syarat dan Ketentuan
          </c-text>
          <c-box
            :font-size="['14px', '16px']"
            :font-weight="['400', '400']"
            white-space="pre-line"
          >
            {{ gift?.termCondition }}
          </c-box>
        </c-box>
      </c-box>

      <c-box
        :position="['fixed', 'relative']"
        width="100%"
        bottom="0px"
        :p="['16px', '30px']"
        :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
        :border-radius="['12px 12px 0 0', '12px']"
        height="fit-content"
        background-color="#FFF"
        :border-top="['1px solid #C4C4C4', 'none']"
        z-index="2"
      > 
        <c-text
          :display="['none', 'block']"
          :font-size="['18px', '20px']"
          :font-weight="['500', 'Bold']"
        >
          Detail Penukaran
        </c-text>
        <c-divider 
          :display="['none', 'block']"
        />
        <c-flex
          flex-direction="column"
          gap="8px"
        >
          <c-flex
            :flex-direction="['row', 'column']"
            justify-content="space-between"
            :gap="['16px', '8px']"
          >
            <c-flex
              justify-content="space-between"
              align-items="center"
            >
              <c-text
                :display="['none', 'block']"
                :font-size="['18px']"
                :font-weight="['500']"
                color="#333"
              >
                Jumlah
              </c-text>
              
              <c-flex
                v-if="!isConfirm"
                align-items="center"
                border="1px solid #008C81"
                gap="8px"
                border-radius="50px"
                overflow="hidden"
              >
                <c-box
                  py="8px"
                  px="8px"
                  height="100%"
                  background-color="primary.400"
                  cursor="pointer"
                  @click.stop="oncChangeQuantity('minus')"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-minus.svg')"
                    height="13px"
                    width="13px"
                    fill="#FFF"
                  />
                </c-box>
                <c-text
                  font-family="Roboto"
                  :font-size="['16px', '18px']"
                  px="4px"
                  font-weight="500"
                  color="primary.400"
                >
                  {{ quantity }}
                </c-text>
                <c-box
                  py="8px"
                  px="8px"
                  height="100%"
                  background-color="primary.400"
                  cursor="pointer"
                  @click.stop="oncChangeQuantity('plus')"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-plus.svg')"
                    height="13px"
                    width="13px"
                    fill="#FFF"
                  />
                </c-box>
              </c-flex>
              <c-text
                v-else
                :font-size="['16px', '18px']"
                font-weight="500"
                color="primary.400"
              >
                {{ quantity }}x
              </c-text>
            </c-flex>
            <c-flex
              :flex-grow="['1', '0']"
              justify-content="space-between"
              align-items="center"
            >
              <c-text
                :display="['none', 'block']"
                :font-size="['18px']"
                :font-weight="['500']"
                color="#333"
              >
                Total
              </c-text>
              <c-text
                :display="['inline', 'none']"
                :font-size="['14px']"
                :font-weight="['400']"
                color="#888"
              >
                Subtotal
              </c-text>
              <c-text
                :font-size="['20px', '28px']"
                :font-weight="['600', '700']"
                color="primary.400"
              >
                {{ totalPoin }} Poin
              </c-text>
            </c-flex>
          </c-flex>
          <c-button
            :font-size="['14px', '16px']"
            :font-weight="['500', 'Regular']"
            variant-color="primary"
            mt="auto"
            w="100%"
            rounded="1000px"
            size="lg"
            :disabled="isLoading || isDisabledSubmit"
            type="button"
            @click.prevent="onClickSubmit"
          >
            {{ isConfirm ? 'Konfirmasi Penukaran' : 'Tukar Sekarang' }}
          </c-button>
        </c-flex>
      </c-box>
    </c-grid>

    <c-box
      v-if="!isConfirm"
      width="100%"
      max-width="1220px"
      margin-inline="auto"
      :margin-top="['0px', '32px']"
      :padding-inline="['16px', '0px']"
      :padding-bottom="['8px', '16px']"
      :background-color="['#FFF']"
    >
      <SimilarGift
        :category="gift?.category"
      />
    </c-box>

    <BaseModal
      :is-open="isOpenConfirm"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            :src="require('@/assets/images/bg-1.jpeg')"
            alt="image get program"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
          flex-direction="column"
        >
          <BaseText
            size-mobile="20px"
            size-desktop="24px"
            color="primary.400"
            text-align="center"
          >
            Apa kamu yakin sudah mengisi data dengan benar?
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-flex
          gap="8px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="danger"
            rounded="1000px"
            width="100%"
            :right-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            right-svg-icon-color="white"
            :disabled="isLoading"
            @click="onCloseConfirm"
          >
            Batal
          </BaseButton>
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            :is-loading="isLoading"
            @click="onSubmitConfirm"
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="showModalNotSufficientPoint"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            :src="require('@/assets/images/bg-2.jpeg')"
            alt="image get program"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="16px 24px"
          flex-direction="column"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
            text-align="center"
          >
            Ups, poin anda tidak mencukupi
          </BaseText>
          <!-- <BaseText
            size-mobile="14px"
            size-desktop="16px"
            text-align="center"
          >
            Poin yang dibutuhkan {{ totalPoin - poin }} poin lagi
          </BaseText> -->
        </c-flex>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            @click="showModalNotSufficientPoint = false"
          >
            Oke
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="isOpenErrorMultipleRedeem"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            :src="require('@/assets/images/illustration-130323-raw.jpeg')"
            alt="image get program"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="16px 24px"
          flex-direction="column"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
            text-align="center"
            margin-bottom="8px"
          >
            Melebihi Batas Penukaran
          </BaseText>
          <BaseText
            size-mobile="14px"
            size-desktop="16px"
            color="primary.400"
            text-align="center"
            margin-bottom="8px"
          >
            Kamu sudah melebihi batas 1 kali penukaran bulan ini, silahkan melakukan penukaran di bulan selanjutnya
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-box
          width="100%"
          text-align="center"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            max-width="200px"
            @click="isOpenErrorMultipleRedeem = false"
          >
            Oke
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import VueSkeletonLoader from 'skeleton-loader-vue'
import EventBus from '@/utils/event-bus'
import { mapGetters, mapActions } from 'vuex'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import { reqReferral } from '@/requests/dietela-api/referral'
import SimilarGift from '@/views/profile/referral/_widgets/similar-gift.vue'

export default {
  components: {
    VueSkeletonLoader,
    BreadcrumbPath,
    BaseText,
    BaseButton,
    BaseModal,
    SimilarGift,
  },
  data() {
    return {
      isLoading: false,
      form: {
        merchantName: '',
        accountNumber: '',
        accountName: '',
        otherMerchant: '',
        productServiceId: '',
        type: '',
      },
      quantity: 1,
      isOpenConfirm: false,
      showModalNotSufficientPoint: false,
      isOpenErrorMultipleRedeem: false,
      gift: null,
      addOns: [],
    }
  },
  computed: {
    ...mapGetters({
      formRedeem: 'generalPersist/formRedeem',      
      poin: 'referral/poin',
      isKol: 'auth/isKol',
    }),
    giftId() {
      return this.$route.params.id
    },
    isDisabledSubmit() {
      return this.$v.$invalid
    },
    totalPoin() {
      if (this.gift?.price) {
        return Number(this.gift.price) * this.quantity
      }
      return 0
    },
    isConfirm() {
      return this.$route.name == 'client.profile.referral.gift.confirm' ? true : false
    },
  },
  validations: {
    form: {
      merchantName: { required: requiredIf((model) => model.type === 'saldo') },
      accountNumber: { required: requiredIf((model) => model.type === 'saldo') },
      accountName: { required: requiredIf((model) => model.type === 'saldo') },
      otherMerchant: { required: requiredIf((model) => model.type === 'saldo' && model.merchantName == 'Lainnya (tambahkan)') },
      productServiceId: { required: requiredIf((model) => model.type === 'programs') },
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      async handler(val) {
        if (this.isKol) {
          this.$toast({
            title: 'Forbidden',
            description: "You don't have permission to access!",
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
          })
          return this.$router.replace({ name: 'client.profile.referral' })
        }

        if (val.name == 'client.profile.referral.gift.confirm') {
          EventBus.$emit('HEADER_BACK', `/profile/referral/gift/${this.giftId}`)
        } else {
          EventBus.$emit('HEADER_BACK', '/profile/referral/redeem')
        }

        if (!this.poin) {
          await this.getPoin()
        }
        await this.init()
        if (this.formRedeem?.referralsGiftsId && this.formRedeem?.referralsGiftsId == val?.params?.id) {
          this.form.merchantName = this.formRedeem.merchantName
          this.form.accountNumber = this.formRedeem.accountNumber
          this.form.accountName = this.formRedeem.accountName
          this.form.otherMerchant = this.formRedeem.otherMerchant
          this.form.productServiceId = this.formRedeem.productServiceId
          this.quantity = this.formRedeem.quantity
          this.form.type = this.gift?.type
        } else {
          this.form.type = this.gift?.type
          this.onResetForm()
          if (this.isConfirm) {
            this.$router.push({
              name: 'client.profile.referral.gift.detail',
              params: {
                id: val?.params?.id,
              },
            })
            return
          }
        }
      },
    },
  },
  methods: {
    ...mapActions({
      getPoin: 'referral/getPoin',
    }),
    async init() {
      try {
        const res = await reqReferral.detailGift(this.$store.getters.axios, {
          giftId: this.giftId,
        })
        this.gift = res?.data?.data?.data
        this.addOns = res?.data?.data?.addOn
        this.form.type = this.gift?.type
        if (this.gift.type === 'saldo') {
          this.addOns.push({
            id: 'Lainnya (tambahkan)',
            bankName: 'Lainnya (tambahkan)',
          })
        }
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.response?.data?.message || 'Something went wrong!',
          status: 'error',
          duration: 3000,
          position: 'bottom-right',
        })
      }
    },
    oncChangeQuantity(type) {
      if (type === 'minus') {
        if (this.quantity <= 1) return
        this.quantity--
      } else if (type === 'plus') {
        this.quantity++
      }
    },
    async onClickSubmit() {
      if (this.isConfirm) {
        this.isOpenConfirm = true
      } else {
        if (this.poin < this.totalPoin) {
          this.showModalNotSufficientPoint = true
          return
        }
        if (this.gift?.isAlreadyRedeem && !this.gift?.isMultipleRedeem) {
          this.isOpenErrorMultipleRedeem = true
          return
        }
        await this.$store.commit('generalPersist/setFormRedeem', {
          ...this.form,
          referralsGiftsId: this.giftId,
          quantity: this.quantity,
        })
        this.$router.push({
          name: 'client.profile.referral.gift.confirm',
          params: {
            id: this.giftId,
          },
        })
      }
    },
    onCloseConfirm() {
      if (!this.isLoading) {
        this.isOpenConfirm = false
      }
      return
    },
    async onSubmitConfirm() {
      this.isLoading = true
      try {
        const payload = {
          referralsGiftsId: this.giftId,
          quantity: this.quantity,
          productServiceId: this.form.productServiceId,
          merchantName: this.form.merchantName === 'Lainnya (tambahkan)' ? this.form.otherMerchant : this.form.merchantName,
          accountNumber: this.form.accountNumber,
          accountName: this.form.accountName,
        }
        const res = await reqReferral.requestRedeem(this.$store.getters.axios, payload)
        this.$store.commit('generalPersist/resetFormRedeem')
        this.$router.push({
          name: 'client.profile.referral.redeem-history',
          params: {
            id: res?.data?.data?.id,
          },
        })
      } catch (err) {
        if (err?.response?.data?.message === 'Can Only Trade 1 Per Month') {
          this.isOpenErrorMultipleRedeem = true
        } else {
          this.$toast({
            title: `${err?.response?.status}: Failed`,
            description: err?.response?.data?.message || 'Something went wrong!',
            status: 'error',
            duration: 3000,
            position: 'bottom-right',
          })
        }
      }
      this.isLoading = false
      this.onCloseConfirm()
    },
    getBankName(bankId) {
      if (bankId && this.addOns.length > 0) {
        const item = this.addOns.find((el) => el.id == bankId)
        return `${item?.bankName}`
      }
      return null 
    },
    getProgramName(productServiceId) {
      if (productServiceId && this.addOns.length > 0) {
        const item = this.addOns.find((el) => el.id == productServiceId)
        return `${item?.product?.name} (${item?.programsService})`
      }
      return null 
    },
    onResetForm() {
      this.form.merchantName = ''
      this.form.accountNumber = ''
      this.form.accountName = ''
      this.form.otherMerchant = ''
      this.form.productServiceId = ''
      this.$store.commit('generalPersist/resetFormRedeem')
    },
  }, 
}
</script>

<style scoped>
::v-deep.ui.search.dropdown > input.search,
::v-deep.ui.selection.dropdown > .dropdown.icon,
::v-deep.ui.multiple.search.dropdown > .text {
  top: auto;
}
::v-deep.ui.dropdown .menu > .item {
  font-size: 18px;
}
::v-deep.hidden {
  display: none;
}
::v-deep.ui.selection.dropdown.invalid {
  box-shadow:  0 0 0 1px #e66673 !important;
  border: 1px solid #e66673 !important;
}
</style>