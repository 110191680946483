var render = function () {
  var _vm$gift, _vm$gift2, _vm$gift3, _vm$gift4, _vm$gift5, _vm$gift6, _vm$gift7, _vm$$v$form$merchantN, _vm$$v$form$otherMerc, _vm$$v$form$accountNu, _vm$$v$form$accountNa, _vm$gift8, _vm$$v$form$productSe, _vm$gift9, _vm$gift10, _vm$gift11;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "max-width": "1270px",
      "width": "100%",
      "background-color": ['#F2F2F2', '#FFF'],
      "padding-bottom": ['125px', '0px'],
      "margin-inline": "auto",
      "min-height": ['calc(100vh - 62px)', 'unset']
    }
  }, [_c('c-flex', {
    attrs: {
      "display": ['none', 'flex'],
      "width": "100%",
      "align-items": "center",
      "gap": "80px",
      "py": "20px"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "align-items": "center",
      "font-size": "16px",
      "font-weight": "400",
      "color": "primary.400",
      "gap": "20px",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icon-chevron-left.svg'),
      "height": "20",
      "width": "20"
    }
  }), _vm._v(" Kembali ")], 1), _c('BreadcrumbPath', {
    attrs: {
      "margin-top": "0px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Profil',
        href: '/profile'
      }, {
        label: 'Kode AjakSehat',
        href: '/profile/referral'
      }, {
        label: 'Hadiah',
        href: '/profile/referral/redeem'
      }, {
        label: _vm.isConfirm ? 'Konfirmasi Penukaran' : 'Detail Hadiah',
        isCurrent: true
      }]
    }
  })], 1), _c('c-text', {
    attrs: {
      "display": ['none', 'block'],
      "text-align": "center",
      "font-family": "Roboto",
      "font-size": "20px",
      "font-weight": "700",
      "mb": "20px",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(_vm.isConfirm ? 'Konfirmasi Penukaran' : 'Detail Hadiah') + " ")]), _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "gap": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "p": ['0px', '30px'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['none', '12px'],
      "background-color": "#FFF"
    }
  }, [_c('c-flex', {
    attrs: {
      "display": ['none', 'flex'],
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['18px', '20px'],
      "font-weight": ['500', 'Bold']
    }
  }, [_vm._v(" Detail Hadiah ")])], 1), _c('c-divider', {
    attrs: {
      "display": ['none', 'block']
    }
  }), _c('c-flex', {
    attrs: {
      "flex-direction": ['column', 'row'],
      "gap": ['16px'],
      "margin-bottom": ['16px', '16px']
    }
  }, [(_vm$gift = _vm.gift) !== null && _vm$gift !== void 0 && _vm$gift.image ? _c('c-image', {
    attrs: {
      "src": (_vm$gift2 = _vm.gift) === null || _vm$gift2 === void 0 ? void 0 : _vm$gift2.image,
      "flex-shrink": "0",
      "width": ['100%', '225px'],
      "height": ['100%'],
      "max-height": ['auto', '125px'],
      "object-fit": "cover"
    }
  }) : _c('c-box', {
    attrs: {
      "flex-shrink": "0",
      "width": ['100%', '225px'],
      "height": ['220px', '125px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "flex-direction": "column"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": ['500', '500'],
      "text-align": ['center', 'left']
    }
  }, [_vm._v(" " + _vm._s((_vm$gift3 = _vm.gift) === null || _vm$gift3 === void 0 ? void 0 : _vm$gift3.name) + " ")]), _c('c-box', {
    attrs: {
      "margin-top": "8px",
      "display": ['none', 'block']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['18px'],
      "font-weight": ['500']
    }
  }, [_vm._v(" Deskripsi Hadiah ")]), _c('c-text', {
    attrs: {
      "display": ['none', 'block'],
      "font-size": ['16px'],
      "font-weight": ['400']
    }
  }, [_vm._v(" " + _vm._s((_vm$gift4 = _vm.gift) === null || _vm$gift4 === void 0 ? void 0 : _vm$gift4.description) + " ")])], 1)], 1)], 1), ((_vm$gift5 = _vm.gift) === null || _vm$gift5 === void 0 ? void 0 : _vm$gift5.type) == 'saldo' || ((_vm$gift6 = _vm.gift) === null || _vm$gift6 === void 0 ? void 0 : _vm$gift6.type) == 'programs' ? _c('c-box', {
    attrs: {
      "margin-bottom": "16px",
      "padding-inline": ['16px', '0px']
    }
  }, [((_vm$gift7 = _vm.gift) === null || _vm$gift7 === void 0 ? void 0 : _vm$gift7.type) == 'saldo' ? _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": ['500', '500'],
      "margin-bottom": ['8px']
    }
  }, [_vm._v(" Data Penerima ")]), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "mb": ['12px', '16px'],
      "is-invalid": (_vm$$v$form$merchantN = _vm.$v.form.merchantName) === null || _vm$$v$form$merchantN === void 0 ? void 0 : _vm$$v$form$merchantN.$invalid
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.isConfirm ? 'primary.400' : '#555555',
      "font-weight": "400",
      "font-family": "Roboto",
      "padding-left": "8px"
    }
  }, [_vm._v(" Nama Merchant/Bank ")]), !_vm.isConfirm ? _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Pilih merchant/bank",
      "height": ['48px', '62px'],
      "font-size": ['14px', '18px'],
      "border-radius": ['8px'],
      "is-invalid": false
    },
    model: {
      value: _vm.form.merchantName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "merchantName", $$v);
      },
      expression: "form.merchantName"
    }
  }, _vm._l(_vm.addOns, function (item) {
    return _c('option', {
      key: item === null || item === void 0 ? void 0 : item.id,
      domProps: {
        "value": item === null || item === void 0 ? void 0 : item.bankName
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.bankName) || '-') + " ")]);
  }), 0)], 1) : _vm._e(), _vm.isConfirm ? _c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": "#111",
      "font-weight": "400",
      "padding-left": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.merchantName === 'Lainnya (tambahkan)' ? _vm.form.otherMerchant : _vm.form.merchantName) + " ")]) : _vm._e()], 1), !_vm.isConfirm && _vm.form.merchantName === 'Lainnya (tambahkan)' ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "mb": ['12px', '16px'],
      "is-invalid": (_vm$$v$form$otherMerc = _vm.$v.form.otherMerchant) === null || _vm$$v$form$otherMerc === void 0 ? void 0 : _vm$$v$form$otherMerc.$invalid
    }
  }, [_c('c-input-group', {
    attrs: {
      "size": "md",
      "mt": ['8px', '12px']
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan merchant/bank lainnya",
      "height": ['48px', '62px'],
      "font-size": ['14px', '18px'],
      "border-radius": ['8px']
    },
    model: {
      value: _vm.form.otherMerchant,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "otherMerchant", $$v);
      },
      expression: "form.otherMerchant"
    }
  })], 1)], 1) : _vm._e(), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "mb": ['12px', '16px'],
      "is-invalid": (_vm$$v$form$accountNu = _vm.$v.form.accountNumber) === null || _vm$$v$form$accountNu === void 0 ? void 0 : _vm$$v$form$accountNu.$invalid
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.isConfirm ? 'primary.400' : '#555555',
      "font-weight": "400",
      "font-family": "Roboto",
      "padding-left": "8px"
    }
  }, [_vm._v(" Nomor E-Wallet/Rekening ")]), !_vm.isConfirm ? _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Masukkan nomor e-wallet/rekening",
      "height": ['48px', '62px'],
      "font-size": ['14px', '18px'],
      "border-radius": ['8px']
    },
    model: {
      value: _vm.form.accountNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "accountNumber", $$v);
      },
      expression: "form.accountNumber"
    }
  })], 1) : _vm._e(), _vm.isConfirm ? _c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": "#111",
      "font-weight": "400",
      "padding-left": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.accountNumber) + " ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "mb": ['12px', '16px'],
      "is-invalid": (_vm$$v$form$accountNa = _vm.$v.form.accountName) === null || _vm$$v$form$accountNa === void 0 ? void 0 : _vm$$v$form$accountNa.$invalid
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.isConfirm ? 'primary.400' : '#555555',
      "font-weight": "400",
      "font-family": "Roboto",
      "padding-left": "8px"
    }
  }, [_vm._v(" Nama Penerima E-Wallet/Rekening ")]), !_vm.isConfirm ? _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "id": "account-name",
      "type": "text",
      "placeholder": "Masukkan nama penerima",
      "height": ['48px', '62px'],
      "font-size": ['14px', '18px'],
      "border-radius": ['8px']
    },
    model: {
      value: _vm.form.accountName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "accountName", $$v);
      },
      expression: "form.accountName"
    }
  })], 1) : _vm._e(), !_vm.isConfirm ? _c('c-form-helper-text', {
    attrs: {
      "id": "account-name",
      "padding-left": "8px",
      "font-size": ['12px', '14px']
    }
  }, [_vm._v(" Nama yang diisi sesuai dengan nama pada buku rekening atau e-wallet ")]) : _vm._e(), _vm.isConfirm ? _c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": "#111",
      "font-weight": "400",
      "padding-left": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.accountName) + " ")]) : _vm._e()], 1)], 1) : _vm._e(), ((_vm$gift8 = _vm.gift) === null || _vm$gift8 === void 0 ? void 0 : _vm$gift8.type) == 'programs' ? _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": ['500', '500'],
      "margin-bottom": ['8px']
    }
  }, [_vm._v(" Jenis Program Pilihan ")]), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "mb": ['12px', '16px'],
      "is-invalid": (_vm$$v$form$productSe = _vm.$v.form.productServiceId) === null || _vm$$v$form$productSe === void 0 ? void 0 : _vm$$v$form$productSe.$invalid
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.isConfirm ? 'primary.400' : '#555555',
      "font-weight": "400",
      "font-family": "Roboto",
      "padding-left": "8px"
    }
  }, [_vm._v(" Pilih program ")]), !_vm.isConfirm ? _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Jenis Program",
      "height": ['48px', '62px'],
      "font-size": ['14px', '18px'],
      "border-radius": ['8px'],
      "is-invalid": false
    },
    model: {
      value: _vm.form.productServiceId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "productServiceId", $$v);
      },
      expression: "form.productServiceId"
    }
  }, _vm._l(_vm.addOns, function (item) {
    return _c('option', {
      key: item === null || item === void 0 ? void 0 : item.id,
      domProps: {
        "value": item === null || item === void 0 ? void 0 : item.id
      }
    }, [_vm._v(" " + _vm._s(_vm.getProgramName(item === null || item === void 0 ? void 0 : item.id)) + " ")]);
  }), 0)], 1) : _vm._e(), _vm.isConfirm ? _c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": "#111",
      "font-weight": "400",
      "padding-left": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.getProgramName(_vm.form.productServiceId)) + " ")]) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "display": ['block', 'none'],
      "margin-bottom": "16px",
      "padding-inline": ['16px', '0px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": ['500', '500'],
      "margin-bottom": ['8px']
    }
  }, [_vm._v(" Deskripsi Hadiah ")]), _c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": ['400', '400']
    }
  }, [_vm._v(" " + _vm._s((_vm$gift9 = _vm.gift) === null || _vm$gift9 === void 0 ? void 0 : _vm$gift9.description) + " ")])], 1), _c('c-box', {
    attrs: {
      "margin-bottom": ['16px', '0px'],
      "padding-inline": ['16px', '0px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": ['500', '500'],
      "margin-bottom": ['8px']
    }
  }, [_vm._v(" Syarat dan Ketentuan ")]), _c('c-box', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": ['400', '400'],
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s((_vm$gift10 = _vm.gift) === null || _vm$gift10 === void 0 ? void 0 : _vm$gift10.termCondition) + " ")])], 1)], 1), _c('c-box', {
    attrs: {
      "position": ['fixed', 'relative'],
      "width": "100%",
      "bottom": "0px",
      "p": ['16px', '30px'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['12px 12px 0 0', '12px'],
      "height": "fit-content",
      "background-color": "#FFF",
      "border-top": ['1px solid #C4C4C4', 'none'],
      "z-index": "2"
    }
  }, [_c('c-text', {
    attrs: {
      "display": ['none', 'block'],
      "font-size": ['18px', '20px'],
      "font-weight": ['500', 'Bold']
    }
  }, [_vm._v(" Detail Penukaran ")]), _c('c-divider', {
    attrs: {
      "display": ['none', 'block']
    }
  }), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "gap": "8px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": ['row', 'column'],
      "justify-content": "space-between",
      "gap": ['16px', '8px']
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "display": ['none', 'block'],
      "font-size": ['18px'],
      "font-weight": ['500'],
      "color": "#333"
    }
  }, [_vm._v(" Jumlah ")]), !_vm.isConfirm ? _c('c-flex', {
    attrs: {
      "align-items": "center",
      "border": "1px solid #008C81",
      "gap": "8px",
      "border-radius": "50px",
      "overflow": "hidden"
    }
  }, [_c('c-box', {
    attrs: {
      "py": "8px",
      "px": "8px",
      "height": "100%",
      "background-color": "primary.400",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.oncChangeQuantity('minus');
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-minus.svg'),
      "height": "13px",
      "width": "13px",
      "fill": "#FFF"
    }
  })], 1), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '18px'],
      "px": "4px",
      "font-weight": "500",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.quantity) + " ")]), _c('c-box', {
    attrs: {
      "py": "8px",
      "px": "8px",
      "height": "100%",
      "background-color": "primary.400",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.oncChangeQuantity('plus');
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-plus.svg'),
      "height": "13px",
      "width": "13px",
      "fill": "#FFF"
    }
  })], 1)], 1) : _c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.quantity) + "x ")])], 1), _c('c-flex', {
    attrs: {
      "flex-grow": ['1', '0'],
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "display": ['none', 'block'],
      "font-size": ['18px'],
      "font-weight": ['500'],
      "color": "#333"
    }
  }, [_vm._v(" Total ")]), _c('c-text', {
    attrs: {
      "display": ['inline', 'none'],
      "font-size": ['14px'],
      "font-weight": ['400'],
      "color": "#888"
    }
  }, [_vm._v(" Subtotal ")]), _c('c-text', {
    attrs: {
      "font-size": ['20px', '28px'],
      "font-weight": ['600', '700'],
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.totalPoin) + " Poin ")])], 1)], 1), _c('c-button', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": ['500', 'Regular'],
      "variant-color": "primary",
      "mt": "auto",
      "w": "100%",
      "rounded": "1000px",
      "size": "lg",
      "disabled": _vm.isLoading || _vm.isDisabledSubmit,
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickSubmit.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isConfirm ? 'Konfirmasi Penukaran' : 'Tukar Sekarang') + " ")])], 1)], 1)], 1), !_vm.isConfirm ? _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1220px",
      "margin-inline": "auto",
      "margin-top": ['0px', '32px'],
      "padding-inline": ['16px', '0px'],
      "padding-bottom": ['8px', '16px'],
      "background-color": ['#FFF']
    }
  }, [_c('SimilarGift', {
    attrs: {
      "category": (_vm$gift11 = _vm.gift) === null || _vm$gift11 === void 0 ? void 0 : _vm$gift11.category
    }
  })], 1) : _vm._e(), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenConfirm,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "160px",
            "object-fit": "cover",
            "src": require('@/assets/images/bg-1.jpeg'),
            "alt": "image get program"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px",
            "flex-direction": "column"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "20px",
            "size-desktop": "24px",
            "color": "primary.400",
            "text-align": "center"
          }
        }, [_vm._v(" Apa kamu yakin sudah mengisi data dengan benar? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "8px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "danger",
            "rounded": "1000px",
            "width": "100%",
            "right-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "right-svg-icon-color": "white",
            "disabled": _vm.isLoading
          },
          on: {
            "click": _vm.onCloseConfirm
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "is-loading": _vm.isLoading
          },
          on: {
            "click": _vm.onSubmitConfirm
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.showModalNotSufficientPoint,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "160px",
            "object-fit": "cover",
            "src": require('@/assets/images/bg-2.jpeg'),
            "alt": "image get program"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": "16px 24px",
            "flex-direction": "column"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400",
            "text-align": "center"
          }
        }, [_vm._v(" Ups, poin anda tidak mencukupi ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              _vm.showModalNotSufficientPoint = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenErrorMultipleRedeem,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "160px",
            "object-fit": "cover",
            "src": require('@/assets/images/illustration-130323-raw.jpeg'),
            "alt": "image get program"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": "16px 24px",
            "flex-direction": "column"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400",
            "text-align": "center",
            "margin-bottom": "8px"
          }
        }, [_vm._v(" Melebihi Batas Penukaran ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "14px",
            "size-desktop": "16px",
            "color": "primary.400",
            "text-align": "center",
            "margin-bottom": "8px"
          }
        }, [_vm._v(" Kamu sudah melebihi batas 1 kali penukaran bulan ini, silahkan melakukan penukaran di bulan selanjutnya ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "text-align": "center",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "max-width": "200px"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenErrorMultipleRedeem = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }