import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=d182de6c&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&id=d182de6c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d182de6c",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CDivider: require('@chakra-ui/vue').CDivider, CImage: require('@chakra-ui/vue').CImage, CFormLabel: require('@chakra-ui/vue').CFormLabel, CSelect: require('@chakra-ui/vue').CSelect, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormControl: require('@chakra-ui/vue').CFormControl, CInput: require('@chakra-ui/vue').CInput, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CButton: require('@chakra-ui/vue').CButton, CGrid: require('@chakra-ui/vue').CGrid})
